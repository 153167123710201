$(function(){

    $.urlParam = function(name){
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        return results[1] || 0;
    };

    if(typeof AJAX_DOWNLOAD_URL !== 'undefined'){
        var isProcessed = false;
        var filename;
        $.ajax({
            type: "get",
            url: AJAX_DOWNLOAD_URL,
            data: {
                "_token": TOKEN
            },

            dataType: 'json',
            success: function (data) {
                $('#overlay').addClass('is-processed');
                filename = data.filename;
                isProcessed = true;
                $('#overlay').find('.percent').text(100);
                $('#overlay').find('.progress').css('width', 100+'%');
                window.location.href = data.url;
            }
        });


        function display_progress() {
            if(isProcessed){
                return;
            }
            $.ajax({
                type: "get",
                url: AJAX_DOWNLOAD_PROCESS_URL,
                data: {
                    "_token": TOKEN,
                },

                dataType: 'json',
                success: function (data) {
                    $('#overlay').find('.percent').text(data);
                    $('#overlay').find('.progress').css('width', data+'%');

                 }
            });
        }

        setInterval(display_progress, 1000);
    }

    $("#sidebar-menu li.dropdown .active").closest('ul.dropdown-menu').css('display', 'block', 'important');

    $('.date-datepicker').datepicker({
        dateFormat: 'dd/mm/yy',
		changeMonth: true,
		changeYear: true,
		yearRange: "-80:+40"
    }).val();

    $(".filter .date-icon").click(function() {
        $(this).closest('div').find('.date-datepicker').datepicker('show');
    });

    $('input#search').keypress(function(e){
        if (e.which == 13) {
            $(this).closest('div').find('.js-filter-all').click();
        }
    });

    $('.js-trigger-attr-a-tag').click(function(){
        $a = $(this).find('a');
        if($a[0]){
            $a[0].click();
        }
    });

    $('.js-filter-all').click(function(){
        var url = $(this).data('url');
        var input = $(this).closest('div').find('input#search');
        var val = encodeURIComponent(input.val());
        var code = input.attr('name');
        var final_url = url + code +'='+val;
        if(url.indexOf("=") != -1){
            final_url = url +'&'+ code +'='+val
        }
        window.location = final_url;
    });


    $('.js-table-row-limits').change(function(){
        var url = $(this).val();
        window.location = url;
    });

    $('.js-view-table-row').click(function(){
       var url = $(this).data('url');
        console.log(url);
        window.location = url;
    });

    $('.js-filter-columns').keypress(function(e){
        if (e.which == 13) {
            e.preventDefault();
            generateSearch($(this));
        }
    });

    function generateSearch($this){
        var this_tr = $this.closest('tr');
        var get_uri = this_tr.find('input, select').serialize();
        var url = this_tr.data('url');
        var final_url = url + get_uri;
        if(url.indexOf("=") != -1){
            final_url = url +'&'+ get_uri;
        }
        window.location = final_url;
    }

    $('form.js-importer').submit(function(e){

        if ($intance){
            e.preventDefault();
            var file_val = $(this).find('input[type="file"]').val();
            var matches = file_val.match(/[^\\]+$/);
            var filename = matches[0];
            var $this = $(this);

            $.ajax({
                type: "POST",
                url: "import/confirm",
                data: {
                    "_token": TOKEN,
                    'filename': filename
                },

                dataType: 'json',
                success: function (data) {
                    console.log(data.file_exist);
                    if (data.file_exist){
                        if(confirm('This file name already exist and will be removed and re-uploaded.')){
                            $intance = false;
                            $this.trigger('submit');
                        }
                    }else{
                        $intance = false;
                        $this.trigger('submit');
                    }
                }
            });
        }
    });

    var $dropdown = $('#sidebar-menu .dropdown');

    if($dropdown.find('a:first-child').hasClass('active')){
        $dropdown.addClass('active');
    }

    var perPage = 30;

    var defaultSelect2Config = (function(col){
        return {
            placeholder: 'Select',
            multiple: true,
            ajax: {
                url: '/admin/reports/distinct_value',
                dataType: 'json',
                delay: 200,
                data: function (params) {
                    var indexClass = $(this).data('index-class');
                    return {
                        q: params.term,
                        page: params.page,
                        index_class: indexClass,
                        col : col,
                        perPage: perPage
                    };
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: $.map(data.data, function (obj) {
                            obj.id = obj[col];
                            return obj;
                        }),
                        pagination: {
                            more: (params.page * perPage) < data.total
                        }
                    };
                },
                cache: true
            },
            allowClear: false,
            minimumInputLength: 0,
            templateResult: function (repo) {
                console.log(repo);
                if (repo.loading) return repo[col];
                var markup = repo[col];
                return markup;
            },
            templateSelection: function (repo) {
                return repo.id || repo[col];
            },
            escapeMarkup: function (markup) {
                return markup;
            }
        }
    });

    (function() {
        var myConfig = defaultSelect2Config('outlet_code');
        $('.js-report-outlet-code').select2(myConfig);
    })();

    (function() {
        var myConfig = defaultSelect2Config('reason');
        $('.js-report-reason').select2(myConfig);
    })();

    (function() {
        var myConfig = defaultSelect2Config('service_number');
        $('.js-report-service-number').select2(myConfig);
    })();

    (function() {
        var myConfig = defaultSelect2Config('rate_plan');
        $('.js-report-rate-plan').select2(myConfig);
    })();

    (function() {
        var myConfig = defaultSelect2Config('description_1');
        $('.js-report-description-1').select2(myConfig);
    })();

    (function() {
        var myConfig = defaultSelect2Config('description');
        $('.js-report-description').select2(myConfig);
    })();


    (function() {
        var myConfig = defaultSelect2Config('band_rate_plan');
        $('.js-report-band-rate-plan').select2(myConfig);
    })();

    $('.js-card-box-filters').click(function(e){
       e.preventDefault();
       console.log($(this).closest('form'));
       $(this).closest('.card-box-filters').find('form').trigger('submit');
    });

    var acqRatefile = $('.js-acq-total-files').data('count-files');
    var retRatefile = $('.js-ret-total-files').data('count-files');

    $('#js-number-files-chart').sparkline([acqRatefile, retRatefile], {
        type: 'pie',
        width: '165',
        height: '165',
        sliceColors: ['#5d9cec', '#f6dd07']
    });

    var acqRateOutletCode = $('.js-acq-total-outlet-code').data('count-outlet-code');
    var retRateOutletCode = $('.js-ret-total-outlet-code').data('count-outlet-code');
    $('#js-number-outlet-code-chart').sparkline([acqRateOutletCode, retRateOutletCode], {
        type: 'pie',
        width: '165',
        height: '165',
        sliceColors: ['#5d9cec', '#f6dd07']
    });

});
